let title = ' | Gestor - Comercial Ivaiporã'

const filial = {
    path: '/filial',
    props: true,
    component: () => import('@/Components/Filial/FilialContainer'),
    children: [
        {
            path: '',
            name: 'FilialLista',
            component: () => import('@/Components/Filial/FilialLista'),
            meta: {
                title: 'Filiais' + title,
                permission: 'filial_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Filiais',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/filial',
                    }
                ]
            }
        },
        {
            path: 'dashboard/vendas',
            name: 'FilialDashboardVendas',
            component: () => import('@/Components/Filial/FilialDashboardVendas'),
            meta: {
                title: 'Dashboard Vendas' + title,
                permission: 'filial_dashboard_vendas',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Filiais',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/filial',
                    },
                    {
                        text: 'Dashboard Vendas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'dashboard/solar',
            name: 'FilialDashboardSolar',
            component: () => import('@/Components/Filial/FilialDashboardSolar'),
            meta: {
                title: 'Dashboard Solar' + title,
                permission: 'filial_dashboard_solar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Filiais',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/filial',
                    },
                    {
                        text: 'Dashboard Solar',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'dashboard/visitas',
            name: 'FilialDashboardVisitas',
            component: () => import('@/Components/Filial/FilialDashboardVisitas'),
            meta: {
                title: 'Dashboard Visitas' + title,
                permission: 'filial_dashboard_visitas',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Filiais',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/filial',
                    },
                    {
                        text: 'Dashboard Visitas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'metas',
            name: 'FilialDefinirMetas',
            component: () => import('@/Components/Filial/FilialDefinirMetas'),
            meta: {
                title: 'Definir Metas' + title,
                permission: 'filial_definir_meta',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Filiais',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/filial',
                    },
                    {
                        text: 'Definir metas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'get/:idfilial',
            props: true,
            name: 'FilialGet',
            component: () => import('@/Components/Filial/FilialGet'),
            meta: {
                title: 'Filial' + title,
                permission: 'filial_get',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Filiais',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/filial',
                    },
                    {
                        text: 'Filial',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'acompanhamento',
            name: 'FilialAcompanhamentoVendedores',
            component: () => import('@/Components/Filial/FilialAcompanhamentoVendedores'),
            meta: {
                title: 'Filiais' + title,
                permission: 'filial_acompanhamento_metas_vendedores',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Filiais',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/filial',
                    }
                ]
            }
        },
    ]
}

export default filial