let title = ' | Gestor - Comercial Ivaiporã'

const dashboard = {
    path: '/',
    props: true,
    component: () => import('@/Components/Inicio/InicioContainer'),
    children: [
        {
            path: '',
            name: 'DashboardVendas',
            component: () => import('@/Components/Dashboard/DashboardVendas'),
            meta: {
                title: title,
                permission: 'dashboard_resumo',
            }
        },
        {
            path: '/dashboard/vendas',
            name: 'DashboardVendasAntiga',
            component: () => import('@/Components/Dashboard/DashboardVendasAntiga'),
            meta: {
                title: title,
                permission: 'dashboard_resumo',
            }
        },
        {
            path: '/dashboard/objetivos',
            name: 'DashboardObjetivos',
            component: () => import('@/Components/Dashboard/DashboardObjetivos'),
            meta: {
                title: 'Objetivos' + title,
                permission: 'dashboard_objetivos',
            }
        },
        {
            path: '/dashboard/bonificacao',
            name: 'DashboardBonificacao',
            component: () => import('@/Components/Dashboard/DashboardBonificacao'),
            meta: {
                title: 'Bonificação' + title,
                permission: 'dashboard_bonificacao',
            }
        },
        {
            path: '/dashboard/aguardando',
            name: 'Aguardando',
            component: () => import('@/Components/Dashboard/DashboardAguardando'),
            meta: {
                title: 'Aguardando' + title,
                permission: 'dashboard_aguardando',
            }
        },
        {
            path: '/dashboard/ranking-vendas',
            name: 'Ranking de Vendas',
            component: () => import('@/Components/Dashboard/DashboardRankingVendas'),
            meta: {
                title: 'Ranking de Vendas' + title,
                permission: 'dashboard_ranking_vendas',
            }
        },
        {
            path: '/dashboard/evolucao',
            name: 'Evolução vendas',
            component: () => import('@/Components/Dashboard/DashboardEvolucao'),
            meta: {
                title: 'Evolução vendas' + title,
                permission: 'dashboard_evolucao_vendas',
            }
        },
        {
            path: '/dashboard/financeiro',
            name: 'Financeiro',
            component: () => import('@/Components/Dashboard/DashboardFinanceiro'),
            meta: {
                title: 'Financeiro' + title,
                permission: 'dashboard_financeiro',
            }
        },
        {
            path: '/dashboard/remessas',
            name: 'Remessas',
            component: () => import('@/Components/Dashboard/DashboardRemessas'),
            meta: {
                title: 'Remessas' + title,
                permission: 'dashboard_remessas',
            }
        },
        {
            path: '/dashboard/compras',
            name: 'Compras',
            component: () => import('@/Components/Dashboard/DashboardCompras'),
            meta: {
                title: 'Compras' + title,
                permission: 'dashboard_compras',
            }
        },
        {
            path: '/dashboard/cd',
            name: 'Cd',
            component: () => import('@/Components/Dashboard/DashboardCd'),
            meta: {
                title: 'Cd' + title,
                permission: 'dashboard_cd',
            }
        },
        {
            path: '/dashboard/onepage',
            name: 'Onepage',
            component: () => import('@/Components/Dashboard/DashboardOnePage'),
            meta: {
                title: 'Categorias' + title,
                permission: 'dashboard_categorias',
            }
        },
        {
            path: '/dashboard/tv',
            name: 'TV',
            component: () => import('@/Components/Dashboard/DashboardTV'),
            meta: {
                title: 'Dash TV' + title,
                permission: 'dashboard_tv',
            }
        },
        {
            path: '/dashboard/comparativo_dre',
            name: 'ComparativoDRE',
            component: () => import('@/Components/Dashboard/DashboardComparativoDRE'),
            meta: {
                title: 'Dash DRE' + title,
                permission: 'comparativo_dre_dashboard',
            }
        },
        {
            path: '/dashboard/vendas_online',
            name: 'VendasOnline',
            component: () => import('@/Components/Dashboard/DashboardVendasOnline.vue'),
            meta: {
                title: 'Vendas Online' + title,
                permission: 'vendas_online',
            }
        },
        {
            path: '/dashboard/marketing',
            name: 'DashboardMarketingTickets',
            component: () => import('@/Components/Dashboard/DashboardMarketingTickets'),
            meta: {
                title: 'Marketing' + title,
                permission: 'dashboard_marketing',
            }
        },
    ],
}

export default dashboard