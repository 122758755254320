let title = ' | Gestor - Comercial Ivaiporã'

const controleVisitas = {
    path: '/controle_visitas',
    props: true,
    component: () => import('@/Components/ControleVisitas/VisitasContainer.vue'),
    children: [
        {
            path: '',
            name: 'FormularioControleVisitas',
            component: () => import('@/Components/ControleVisitas/VisitasFormulario.vue'),
            meta: {
                title: 'Controle de Visitas à obra' + title,
                permission: 'controle_visitas_form',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Formulário de registro de visita a obra',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/controle_visitas',
                    }
                ]
            }
        },
        {
            path: 'resumo/rede',
            name: 'ResumoControleVisitasRede',
            component: () => import('@/Components/ControleVisitas/VisitasResumoRede.vue'),
            meta: {
                title: 'Resumo Visitas' + title,
                permission: 'controle_visitas_resumo',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Dashboard de visitas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
    ]
}

export default controleVisitas